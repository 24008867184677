<!--意见反馈-->
<template>
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col style="background: #fff; padding: 20px 0;margin-bottom: 30px;">
        <el-row>
          <el-col :span="13" :offset="1">
            <img src="@/assets/img/giEdifice/feedbackImg.png" style="width: 100%; height: 500px">
          </el-col>
          <el-col :span="8" :offset="1">
            <el-form ref="form" :model="form" :rules="rules">
              <el-row class="formRowClass">
                <el-form-item prop="title">
                  <el-input v-model="form.title" placeholder="留言主题"></el-input>
                </el-form-item>
              </el-row>
              <el-row class="formRowClass">
                <el-form-item prop="userConnection">
                  <el-input v-model="form.userConnection" placeholder="联系方式"></el-input>
                </el-form-item>
              </el-row>
              <el-row class="formRowClass">
                <el-form-item prop="content">
                  <el-input type="textarea" :rows="11" placeholder="留言内容" v-model="form.content">
                  </el-input>
                </el-form-item>
              </el-row>
              <el-row class="formRowClass">
                <el-form-item prop="code" class="imgFormItem">
                  <el-input type="text" v-model="form.code" name="kaptcha" placeholder="请输入"
                            @keyup.enter.native="onSubmit">
                    <template slot="prepend">
                      <img src="@/assets/img/login/code-icon.png">
                    </template>
                    <template slot="append">
                      <img class="verify-code" :src="kaptchaUrl">
                    </template>
                  </el-input>
                  <div class="switchImg cursorPointer" @click="getKaptcha">换一张</div>
                </el-form-item>
              </el-row>
            </el-form>
            <div>
              <el-button class="btnClass" type="primary" @click="onSubmit" v-loading="btnLoading">提交</el-button>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
import { v1 as uuid } from 'uuid';
import topPathList from '@/components/topPathList'

export default {
  name: 'feedback',
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '意见反馈',
          path: '/giEdifice/feedback'
        },
      ],
      form: {
        title: '',
        userConnection: '',
        content: '',
        code: '',
        userName: ''
      },
      rules: {
        title: [{ required: true, message: '请输入', trigger: 'blur' }],
        userConnection: [{ required: true, message: '请输入', trigger: 'blur' }],
        content: [{ required: true, message: '请输入', trigger: 'blur' }],
        code: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      kaptchaUrl: '',
      btnLoading: false,
    }
  },
  mounted () {
    this.getKaptcha();
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          axios({
            method: "post",
            url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/feedBack/submitComments",
            data: this.form
          }).then(res => {
            if (res.data.code === 10000 || res.data.code === 200) {
              this.btnLoading = false
              this.$message.success('意见反馈提交成功')
              // 预约成功后3s返回大厦首页
              setTimeout(() => {
                this.$router.push({
                  path: '/giEdifice/index',
                })
              }, 3000)
            } else {
              this.btnLoading = false
              this.getKaptcha();
              this.$message.warning(res.data.msg)
            }
            this.btnLoading = false
          }).catch(err => {
            this.getKaptcha();
            this.$message.error('提交失败')
            this.btnLoading = false
          })
        }
      })
    },
    getKaptcha() {
      const api = process.env.VUE_APP_HUSSAR_DEFAULT_API;
      const randomUuid = uuid();
      this.kaptchaUrl = api + '/kaptcha?t=' + new Date().getTime() + '&kaptchasuffix=' + randomUuid;
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.formRowClass {

}
.verify-code{
  width: 80px;
  height: 98%;
  cursor: pointer;
}
.btnClass {
  width: 100%;
  background: linear-gradient(90deg, #22C5FF 0%, #186CF5 100%);
  border-radius: 5px;
}
.switchImg {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: bold;
  color: #186CF5;
  line-height: 18px;
  width: 40px;
  margin-left: 5px;
}
::v-deep .el-input-group {
  width: calc(100% - 50px);
}
::v-deep .imgFormItem .el-form-item__content {
  display: flex;
  align-items: center;
}
</style>
